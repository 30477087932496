<template>
  <div class="component">
    <van-action-sheet v-model="show" title="选择活码">
      <div class="content" style="color: #818181;">

        <div class="user-list">
          <van-radio-group v-if="user.length > 0" icon-size="15px" v-model="radio">
            <div v-for="(item,index) in user" :key="index" :class="{'active-background':  radio == item.id }" class="user" style="padding: 10px 16px;">
              <div>
                {{ item.name }}
              </div>
              <van-radio  shape="square" :name="item"></van-radio>
            </div>
          </van-radio-group>
          <van-empty
              v-else
              class="custom-image"
              :image="require('../../../assets/images/mobiletodo.png')"
              description="暂无渠道活码"
          />
        </div>
        <van-button @click="determine" block style="width: 90%;margin: 0 auto;margin-top: 10px;margin-bottom: 10px" type="info">确定</van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>

import {employeeIndex,channelCodeIndex, channelCodeGroup, store} from "../../../api/channelCode";

export default {
  data() {
    return {
      show:false,
      radio: '',
      // 搜索名称
      serchValue: '',
      user:[]
    }
  },
  watch: {},
  methods: {
    $show(){
      this.show = true
      channelCodeIndex({groupId: 0}).then(res=>{
        this.user = res.data
      })
    },
    determine (e) {
      if (!this.radio) {
        Toast.fail('请选择分组');
      }
      const data = this.radio
      this.$emit("change",data)
      this.show = false
      this.radio = ''
    },
    search(e){
      store({name:e}).then(res=>{
        this.$show()
        this.serchValue = ''
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-empty__image {
  width: 129px !important;
  height: 115px !important;
}
/deep/.van-action-sheet__header{
  font-weight: bolder;
}
.component {
  .content{
    padding: 0;
    .user-list{
      height: 275px;
      overflow: auto;
      .user{
        display: flex;
        align-items: center;
        justify-content: space-between;
        div{
          font-size: 14px;
          color: #252528;
          img{
            width: 46px;
            height: 46px;
            margin-right: 6px;
          }
        }
      }
      .active-background{
        background: #f6fbff !important;
      }
    }
  }
}
</style>
