<template>
  <div class="index">
    <div class="content" style="overflow:hidden;">
      <div class="basecard">
        <div class="header">
          <p class="header__title">活码统计</p>
          <div class="header__right" style="flex: none;">
            <img
                src="../../assets/images/customer-tips.svg"
                class="fs14 svg-icon"

                style="margin-left: 2.5px; width: 14px; height: 14px;">
            <div class="switch">
              <p @click="customerBase(false)" :class="{ time: !roomDate && roomDate !== '1' }" class="switch__item switch__item--active">
                近7日
              </p>
              <p @click="customerBase(true)" :class="{ time: roomDate && roomDate !== '1' }" class="switch__item">近30天</p></div>
          </div>
          <div style="    font-size: 12px;
    position: absolute;
    bottom: 7px;
    margin-bottom: 2px;
    right: 19px;" @click="show=true">
            筛选
            <van-icon name="filter-o"/>
          </div>

        </div>
        <div class="databox">
          <div style="padding-top: 15px;padding-bottom: 15px;" class="list customer van-hairline--surround">
            <div style="width: 50%;" class="list__item mgb40">
              <p class="list__item__count">{{ roomStatistics.addNum }}</p>
              <p class="list__item__desc">今日添加客户数(人)</p></div>
            <div style="width: 50%;" class="list__item mgb40">
              <p class="list__item__count">{{ roomStatistics.defriendNum }}</p>
              <p class="list__item__desc">今日流失客户数(人)
              </p>
            </div>
            <div style="width: 50%;" class="list__item mgb40">
              <p class="list__item__count">{{ roomStatistics.addNumLong }}</p>
              <p class="list__item__desc">总添加客户数(人)
              </p></div>
            <div style="width: 50%;       margin-bottom: 20px;" class="list__item">
              <p class="list__item__count">{{ roomStatistics.defriendNumLong }}</p>
              <p class="list__item__desc align__center">总流失客户数(人)
              </p></div>
          </div>
        </div>
        <div class="chart__box">
          <p class="charts__tips">
          </p>
          <div class="Echarts" style='padding-left: 12px;'>
            <div id="myChart" :style="{ height: '300px'}"></div>
          </div>
        </div>
      </div>
      <div class="basecard mgt16">
        <div class="header"><p class="header__title">表格数据</p></div>
        <div class="content">
          <a-table
              bordered
              :scroll="{ x: 350 }"
              tableLayout="auto"
              rowKey="time"
              size="small"
              :pagination="pagination"
              @change="handleTableChange"
              :columns="columns"
              :data-source="tableData">
          </a-table>
        </div>
        <div>
        </div>
      </div>
      <!--   筛选   -->
      <van-action-sheet v-model:show="show" title="筛选">
        <div class="content">
          <van-cell title="渠道码" @click="$refs.grouping.$show()" :value="groupName.name">
          </van-cell>
          <van-cell title="类型" @click="typeShow = true" :value="typeName">
          </van-cell>
          <van-cell arrow-direction="down" title="时间" :value="addTimeData || '请选择时间'" @click="addTimeShow = true"/>
          <van-calendar :min-date="new Date('2000-01-01')" v-model="addTimeShow" color="#1989fa" type="range" @confirm="onAddConfirm"/>

          <div style="display:flex;align-items: center;justify-content: space-around;margin: 30px 0;margin-top: 100px">
            <van-button style="width: 40%;" type="default" @click="show=false">取消</van-button>
            <van-button style="width: 40%;" @click="customerBase('1')" type="info">确定</van-button>
          </div>
        </div>
      </van-action-sheet>
      <!--   分组   -->
      <grouping ref="grouping" @change="groupDate"/>
      <!--   类型   -->
      <van-action-sheet   cancel-text="取消"
                          close-on-click-action
                          @cancel="typeShow = false" v-model:show="typeShow" :actions="actions" @select="typeSelect" />
    </div>
  </div>
</template>

<script>
import grouping from "./components/ChannelId";
import {workEmployee, roomStatistics, statisticsCode, statisticsIndexCode} from '../../api/pullNew'

export default {
  components: {
    grouping
  },
  data() {
    return {
      // 文字
      message: '',
      // tbas切换
      tabsChose: 0,
      active: '2',
      seven: this.dateFormat(new Date() - (86400 * 7 * 1000)),
      thirty: this.dateFormat(new Date() - (86400 * 30 * 1000)),
      // dayStatistics
      dayStatistics: {
        date: [],
        add: [],
        loss: []
      },
      statistics: {},
      activeDate: false,
      roomDate: false,
      employeeRanking: [],
      employee: [],
      roomStatistics: {},
      chartCategory: {
        date: [],
        add: [],
        loss: [],
        eoss: []
      },
      today: this.dateFormat(new Date()),
      // 饼图
      pieDatas: [],

      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true
      },
      columns: [
        {
          align: 'center',
          title: '时间',
          dataIndex: 'time'
        },
        {
          align: 'center',
          title: '新增客户数',
          dataIndex: 'addNumRange'
        },
        {
          align: 'center',
          title: '被客户删除/拉黑人数',
          dataIndex: 'defriendNumRange'
        },
        {
          align: 'center',
          title: '删除人数',
          dataIndex: 'deleteNumRange'
        },
        {
          title: '净增客户数',
          align: 'center',
          dataIndex: 'netNumRange'
        }
      ],
      tableData: [
        {
          addNumRange: 0,
          defriendNumRange: 0,
          deleteNumRange: 0,
          netNumRange: 0,
          time: "2022-02-23"
        },
        {
          addNumRange: 0,
          defriendNumRange: 0,
          deleteNumRange: 21,
          netNumRange: 0,
          time: "2022-02-24"
        },
        {
          addNumRange: 3,
          defriendNumRange: 0,
          deleteNumRange: 0,
          netNumRange: 0,
          time: "2022-02-25"
        },
        {
          addNumRange: 0,
          defriendNumRange: 0,
          deleteNumRange: 0,
          netNumRange: 0,
          time: "2022-02-26"
        },],
      show: false,
      groupName: {
        name: '',
        groupId: '',
      },
      typeShow: false,
      actions: [
        { name: '新增客戶数' },
        { name: '净增客户数' },
        { name: '流失客户数' },
      ],
      typeName: '新增客戶数',
      addTimeShow: false,
      addTimeData: '',
      item: null
    }
  },
  watch: {
    active(e) {
      const that = this
      if (e == '2') {
        setTimeout(function () {
          that.categoryCategory()
        }, 1)
      }
    }
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    // 图表数据
    Category() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChartCategory'))
      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: 'category',
          data: this.dayStatistics.date
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.dayStatistics.add,
            type: 'line',
            smooth: true
          },
          {
            data: this.dayStatistics.loss,
            type: 'line',
            smooth: true
          }
        ]
      });
    },
    Category2() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('Category'))
      // 绘制图表
      let index = 0;
      const datas = this.pieDatas
      const colorList = ['#545d93', '#8543E0', '#f4c170', '#6189f2', '#29d0d2', '#ed4981']
      myChart.setOption({
        title: {
          x: 'center',
          y: 'center',
          textStyle: {
            fontSize: 12
          }
        },
        legend: {
          left: 40,
          bottom: 0,
          right: 40,
          itemWidth: 7,
          itemHeight: 7,
          itemGap: 20,
          borderRadius: 4,
          textStyle: {
            color: '#262C41',
            fontSize: 10
          },
          data: datas
        },
        tooltip: {
          trigger: 'item'
        },
        series: [{
          type: 'pie',
          center: ['50%', '50%'],
          radius: ['30%', '45%'],
          clockwise: true,
          avoidLabelOverlap: true,

          itemStyle: {
            normal: {
              color: function (params) {
                return colorList[params.dataIndex]
              }
            }
          },
          label: {
            show: true,
            position: 'outside',
            formatter: '{d}%',
            rich: {
              hr: {
                backgroundColor: 't',
                borderRadius: 3,
                width: 3,
                height: 3,
                padding: [3, 3, 0, -12]
              },
              a: {
                padding: [-30, 15, -20, 15]
              }
            }
          },
          labelLine: {
            normal: {
              length: 20,
              length2: 30,
              lineStyle: {
                width: 1
              }
            }
          },
          data: datas,
        }],
      })
    },
    categoryCategory() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: 'category',
          data: this.chartCategory.date
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.chartCategory.add,
            type: 'line',
            smooth: true
          },
          {
            data: this.chartCategory.loss,
            type: 'line',
            smooth: true
          },
          {
            data: this.chartCategory.eoss,
            type: 'line',
            smooth: true
          },
          {
            data: this.chartCategory.net,
            type: 'line',
            smooth: true
          }
        ]
      });
    },
    // 日期格式化
    dateFormat(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
      return Y + M + D
    },
    // 获取数据
    customer(e) {
      this.activeDate = e
      workEmployee({
        day: this.activeDate ? this.thirty : this.seven
      }).then(res => {
        this.dayStatistics = {
          date: [],
          add: [],
          loss: []
        }
        res.data.dayStatistics.map(item => {
          this.dayStatistics.date.push(item.day)
          this.dayStatistics.add.push(item.add)
          this.dayStatistics.loss.push(item.loss)
        })
        this.pieDatas = res.data.contactSource.map(item => {
          return {
            name: item.addWayName,
            value: item.count
          }
        })
        this.Category2()

        this.Category()
        this.statistics = res.data.statistics
        this.employeeRanking = res.data.employeeRanking

      })
    },
    customerBase(e) {
      this.roomDate = e
      let data
      let dataP
      if(this.roomDate !== '1'){
        data = {
          startTime: this.roomDate ? this.thirty : this.seven,
          endTime: this.today,
          type: 1,
          channelCodeId: this.groupName.groupId
        }
        dataP = {
          startTime: this.roomDate ? this.thirty : this.seven,
          endTime: this.today,
          type: 2,
          channelCodeId: this.groupName.groupId,
          page: 1,
          perPage: 10000
        }
      }else{
        data = {
          startTime: this.starTime,
          endTime: this.endTime,
          type: 1,
          channelCodeId: this.groupName.groupId
        }
        dataP = {
          startTime: this.starTime,
          endTime: this.endTime,
          type: 2,
          channelCodeId: this.groupName.groupId,
          page: 1,
          perPage: 10000
        }
      }

      statisticsCode(data).then(res => {
        this.roomStatistics = res.data
        this.chartCategory = {
          date: [],
          add: [],
          loss: [],
          eoss: [],
          net: []
        }
        res.data.list.map(item => {
          this.chartCategory.date.push(item.time)
          this.chartCategory.add.push(item.addNumRange)
          this.chartCategory.loss.push(item.deleteNumRange)
          this.chartCategory.eoss.push(item.defriendNumRange)
          this.chartCategory.net.push(item.netNumRange)
        })
        const that = this
        if(this.active == '2'){
          that.categoryCategory()
        }

        // this.employee = res.data.employeeRanking
      })
      statisticsIndexCode(dataP).then(res=>{
        this.tableData = res.data.list
      })

      this.show = false

    },
    handleTableChange({current, pageSize}) {
      this.pagination.current = current
      this.pagination.pageSize = pageSize
      // this.getStatisticsIndex()
    },
    // 获取分组
    groupDate(data) {
      this.groupName = {
        name: data.name,
        groupId: data.channelCodeId,
      }
      // this.codeData()
    },
    typeSelect(e){
      this.typeName = e.name
    },
    // 添加时间
    formatDate(date) {
      const year = date.getFullYear()
      const month = date.getMonth() + 1 > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1)
      const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
      return year + '-' + month + '-' + day
    },
    onAddConfirm(date) {
      const [start, end] = date;
      this.addTimeShow = false;
      this.starTime = this.formatDate(start)
      this.endTime = this.formatDate(end)
      this.addTimeData = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      this.screen()
    },
  },
  mounted() {
    var that = this
    this.item = JSON.parse(this.$route.query.item)
    this.groupName = {
      groupId: this.item.channelCodeId,
      name: this.item.name,
    }
    setTimeout(function () {
      // that.customer()
      that.customerBase()
    }, 1)
  },
}
</script>

<style lang="less" scoped>
/deep/ .ant-table-column-title {
  white-space: nowrap !important;
  font-size: 12px !important;
}

/deep/ .van-tabs__line {
  bottom: 9px;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 8px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  background: #F1f2f5;
}

/deep/ .van-empty__image img {
  object-fit: contain;
}

.index {
  font-size: 14px;
  height: 100%;
  background: #fff;

  p {
    margin: 0 !important;
  }

  .fs17 {
    font-size: 17px;
  }

  .fs14 {
    font-size: .14px;
  }

  .content {
    background: #fff;
    min-height: 92%;
    padding-top: 5px;

    .time {
      font-weight: bolder !important;
      color: #444 !important;
    }

    img {
      width: 22px;
      height: 22px;
    }

    .mgt16 {
      margin-top: 8px;
    }

    .basecard {
      background-color: #fff;
      padding: 0 0 24px 0;

      .header {
        height: 65px;
        padding: 0 15px 20px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .header__title {
          font-size: 18px;
          line-height: 21px;
          font-weight: 500;
          color: #000;
        }

        .header__right {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .svg-icon {
            vertical-align: -0.15em;
            fill: currentColor;
            overflow: hidden
          }

          .switch {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .switch__item--active {
              font-weight: 500;
              color: #000;
            }

            .switch__item {

              padding: 0 3px;
              margin-left: 7px;
              color: #8c8c8c;
              font-size: 12px;
            }
          }
        }

      }

      .databox {
        padding: 0 16px;

        .list {
          border-radius: 15px;
          padding: 36px 0;
          overflow: hidden;
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-wrap: wrap;
          padding-top: 30px;
          padding-bottom: 30px;

          .list__item__desc {
            font-size: 11px;
            line-height: 16px;
            color: #545454;
          }

          .list__item__count {
            font-size: 20px;
            line-height: 21px;
            margin-bottom: 4px;
            font-weight: 500;
            color: #000;
            text-shadow: 0 3px 6px rgb(0 0 0 / 16%);
          }

          .mgb40 {
            margin-bottom: 20px;
          }

          .list__item {
            width: 30%;
            text-align: center;
          }
        }

        .customer {

        }
      }

      .chart__box {
        position: relative;
        height: 250px;

        .charts__tips {
          font-size: 10px;
          padding: 5px 16px 0;
          text-align: right;
          color: #b2b2b2;
        }
      }

      .content {
        padding: 0 16px;
        background-color: #fff;

        .rank {
          .header {
            position: relative;
            padding: 18px 28px 18px 26px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 24px;
            font-weight: 500;
            border-radius: 15px 15px 0 0;
            color: #fff;
            background-color: #294ba3;

            &:after {
              content: " ";
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-bottom: 8px solid #fff;
              position: absolute;
              bottom: 0;
              left: 79px;
            }

            .header__title {
              line-height: 0.56rem;
              font-weight: 500;
              color: #fff;
            }

            .header__time {
              padding-left: 32px;
              position: relative;

              &:after {
                border-left-width: 1px !important;
                position: absolute;
                box-sizing: border-box;
                content: " ";
                pointer-events: none;
                top: -50%;
                right: -50%;
                bottom: -50%;
                left: -50%;
                border: 0 solid #f2f4f8;
                -webkit-transform: scale(.5) !important;
                transform: scale(.5);
              }
            }
          }
        }
      }

      .yesterdayAdd {
        padding: 13px 15px;
        min-height: 120px;
        border: 1px solid #f2f1f1;
        border-radius: 0 0 16px 16px;
        color: #000;
        font-size: 14px;

        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>
